import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-table-plain',
  styleUrl: 'x-table-plain.sass',
})
export class XTablePlain {
  @Prop({ reflect: true }) gap: string;
  @Prop({ reflect: true }) rowGap: string = 'xs';
  @Prop({ reflect: true }) columnGap: string = 'l';

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
