x-table-plain
  width: 100%
  table
    +preset(2)
    width: 100%
    th,
    td
      vertical-align: top
      &[valign="middle"]
        vertical-align: middle
      &[valign="bottom"]
        vertical-align: bottom
    th
      text-align: left
      font-weight: normal
      color: var(--on-surface-dark-50)
    td
      color: var(--on-surface-dark)

    &[inline]
      table
        display: inline-table
        width: auto

  @each $key, $value in $gaps
    &[row-gap="#{$key}"] > table > tbody
      > tr + tr > th,
      > tr + tr > td
        padding-top: #{$value}px
    &[column-gap="#{$key}"] > table > tbody
      > tr > * + *,
      > tr > * + *
        padding-left: #{$value}px
    &[gap="#{$key}"] > table > tbody
      > tr + tr > th,
      > tr + tr > td
        padding-top: #{$value}px
      > tr > * + *,
      > tr > * + *
        padding-left: #{$value}px
